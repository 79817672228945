import React, { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useTransition, animated } from "react-spring";
import styled from "styled-components";
import {
  BsPlusCircle as PlusIcon,
  BsDashCircle as MinusIcon,
} from "react-icons/bs";
import TermDetail from "./TermDetail";
import BreadcrumbBar from "./BreadcrumbBar";
import TermLink from "./TermLink";
import AddToRecordButton from "./AddToRecordButton";
import StatusMessage from "./StatusMessage";

const SearchDescription = styled.p`
  font-size: var(--type-size-larger);
  .highlight {
    color: var(--color-academies-blue);
    font-weight: bold;
  }
  border-bottom: 1.5px solid #999999;
  padding-bottom: 1rem;
  margin: 0;
`;

const Result = styled.div`
  padding: 1rem 0;
  border-bottom: 1.5px solid #c4c4c4;
`;

const ResultHeader = styled.div`
  a {
    font-size: var(--type-size-larger);
    font-weight: 400;
    color: var(--color-academies-blue);
  }
  em {
    font-size: var(--type-size-smaller);
    font-weight: 400;
  }
  svg {
    flex-shrink: 0;
  }
  margin: 0;
  padding: 0rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const ResultExpandButton = styled.button`
  display: flex;
  text-align: left;
  justify-content: space-between;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  align-items: center;
  gap: 0.5rem;
  &:hover {
    cursor: pointer;
  }
`;

const ResultContainer = styled.div`
  max-height: 60rem;
  padding-right: 0.5rem;
  overflow-y: auto;

  @media (max-width: 576px) {
    max-height: 40rem;
  }

  &.embedded {
    max-height: none;
    overflow-y: none;
  }
`;

const ResultBroader = styled.div`
  a,
  span {
    font-style: italic;
    font-size: var(--type-size-smaller);
    color: grey !important;
  }
`;

const UseForText = styled.span`
  font-style: italic;
  color: grey;
  font-size: var(--type-size-medium);
`;

const iconStyles = { color: "var(--color-academies-blue)", size: "1.25rem" };

function SearchResults(props) {
  gtag('event', 'page_view');
  const { embeddedSearchTerm, setEmbeddedTermUri } = props;

  // If not embedded, get search term from query param. Redirect to Home if missing.
  let searchTerm;
  if (embeddedSearchTerm) {
    searchTerm = embeddedSearchTerm;
  } else {
    const params = new URLSearchParams(useLocation().search);
    searchTerm = params.get("term");
    if (!searchTerm) {
      return <Redirect to="/" />;
    }
  }

  const [results, setResults] = useState([]);
  const [breadcrumbTerms, setBreadcrumbTerms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [expandedResultIndex, setExpandedResultIndex] = useState(-1);
  const [expandedTermDetails, setExpandedTermDetails] = useState(null);

  const transitions = useTransition(
    expandedResultIndex !== -1 && expandedTermDetails,
    {
      from: { opacity: 0, maxHeight: "0px" },
      enter: { opacity: 1, maxHeight: "1000px" },
      leave: { opacity: 0, maxHeight: "0px" },
    }
  );

  useEffect(() => {
    let isSubscribed = true;
    if (searchTerm.length < 3) {
      setLoading(false);
      setErrorMessage(
        "Please enter a search term of at least three characters."
      );
    } else {
      setLoading(true);
      setResults([]);
      setExpandedResultIndex(-1);
      fetch(
        `/api/getSearchResults?${new URLSearchParams({
          text: searchTerm,
        })}`
      )
        .then((resp) => resp.json())
        .then((data) => {
          if (isSubscribed) {
            const exactMatches = data.filter(
              (result) =>
                result.label.toLowerCase() === searchTerm.toLowerCase()
            );

            const otherResults = data
              .filter((result) => !exactMatches.includes(result))
              .sort((a, b) =>
                a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
              );
            setResults(exactMatches.concat(otherResults));
            setLoading(false);
            setExpandedResultIndex(0);
            setErrorMessage("");
          }
        })
        .catch(() => {
          if (isSubscribed) {
            setLoading(false);
            setErrorMessage("Error: Unable to retrieve search results.");
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [searchTerm]);

  useEffect(() => {
    let isSubscribed = true;
    if (results.length && expandedResultIndex >= 0) {
      setExpandedTermDetails(null);
      setBreadcrumbTerms([]);
      const termUri = results[expandedResultIndex].uri;
      fetch(
        `/api/getConceptDetails?${new URLSearchParams({ uriOrId: termUri })}`
      )
        .then((resp) => resp.json())
        .then((data) => {
          if (isSubscribed) {
            setExpandedTermDetails(data);
          }
        });

      fetch(
        `/api/getConceptParents?${new URLSearchParams({
          uriOrId: termUri,
        })}`
      )
        .then((resp) => resp.json())
        .then((data) => {
          if (isSubscribed) {
            setBreadcrumbTerms(data);
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [expandedResultIndex, results]);

  if (loading) {
    return <span>Loading search results...</span>;
  }

  return (
    <>
      <h2>Search Results</h2>
      {!errorMessage ? (
        <SearchDescription>
          Your search for <span className="highlight">{searchTerm}</span>{" "}
          returned {results.length ? "the following:" : "no results."}
        </SearchDescription>
      ) : (
        <StatusMessage message={errorMessage} type="error" />
      )}
      <ResultContainer className={embeddedSearchTerm ? "embedded" : null}>
        {results.map((result, resultIndex) => {
          let BroaderTerm = () => (
            <ResultBroader>
              <span>Broader Term: </span>
              <TermLink
                uri={result.broaderUri}
                label={result.broaderLabel}
                setEmbeddedTermUri={setEmbeddedTermUri}
              />
            </ResultBroader>
          );
          if (resultIndex === expandedResultIndex && breadcrumbTerms.length) {
            BroaderTerm = () => (
              <BreadcrumbBar
                terms={breadcrumbTerms}
                setEmbeddedTermUri={setEmbeddedTermUri}
              />
            );
          } else if (result.broaderLabel === "TRT") {
            BroaderTerm = () => (
              <ResultBroader>
                <span>Top-Level Term</span>
              </ResultBroader>
            );
          }

          return (
            <Result key={`${result.uri}_${result.broaderUri}`}>
              <ResultHeader>
                <ResultExpandButton
                  type="button"
                  onClick={
                    resultIndex !== expandedResultIndex
                      ? () => {
                          setExpandedResultIndex(resultIndex);
                          setExpandedTermDetails(null);
                          setBreadcrumbTerms([]);
                        }
                      : () => {
                          setExpandedResultIndex(-1);
                          setExpandedTermDetails(null);
                          setBreadcrumbTerms([]);
                        }
                  }
                >
                  <div>
                    <TermLink
                      uri={result.uri}
                      label={result.label}
                      setEmbeddedTermUri={setEmbeddedTermUri}
                    />
                    {result.useFor && (
                      <UseForText> USE FOR {result.useFor}</UseForText>
                    )}
                  </div>
                  {resultIndex === expandedResultIndex ? (
                    <MinusIcon
                      color={iconStyles.color}
                      size={iconStyles.size}
                    />
                  ) : (
                    <PlusIcon color={iconStyles.color} size={iconStyles.size} />
                  )}
                </ResultExpandButton>
                {embeddedSearchTerm && (
                  <AddToRecordButton
                    termUri={result.uri}
                    termLabel={result.label}
                  />
                )}
              </ResultHeader>
              <BroaderTerm />
              {resultIndex === expandedResultIndex &&
                expandedTermDetails &&
                transitions(
                  (styles, item) =>
                    item &&
                    item.uri === expandedTermDetails.uri && (
                      <animated.div style={styles}>
                        <TermDetail
                          uri={expandedTermDetails.uri}
                          source={expandedTermDetails.source}
                          definitions={expandedTermDetails.definitions}
                          broader={expandedTermDetails.broader}
                          narrower={expandedTermDetails.narrower}
                          related={expandedTermDetails.related}
                          altLabels={expandedTermDetails.altLabels}
                          scopeNotes={expandedTermDetails.scopeNotes}
                          created={expandedTermDetails.created}
                          modified={expandedTermDetails.modified}
                          setEmbeddedTermUri={setEmbeddedTermUri}
                        />
                      </animated.div>
                    )
                )}
              {resultIndex === expandedTermDetails && !expandedTermDetails ? (
                <em>Loading details...</em>
              ) : null}
            </Result>
          );
        })}
      </ResultContainer>
    </>
  );
}

SearchResults.propTypes = {
  embeddedSearchTerm: PropTypes.string,
  setEmbeddedTermUri: PropTypes.func,
};

SearchResults.defaultProps = {
  embeddedSearchTerm: null,
  setEmbeddedTermUri: null,
};

export default SearchResults;
