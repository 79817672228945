import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import smoothscroll from "smoothscroll-polyfill";
import TermDetail from "./TermDetail";
import BreadcrumbBar from "./BreadcrumbBar";
import AddToRecordButton from "./AddToRecordButton";
import { extractTermId } from "./utils";

// Support smooth scrolling in Safari.
smoothscroll.polyfill();

const Header = styled.h3`
  color: var(--color-academies-blue);
  font-size: var(--type-size-large);
  margin-bottom: 0;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

function TermDisplay(props) {
  gtag('event', 'page_view');
  const { embeddedTermUri, setEmbeddedTermUri } = props;

  let termId;
  if (!embeddedTermUri) {
    ({ termId } = useParams());
  } else {
    termId = extractTermId(embeddedTermUri);
  }
  const isEmbedded = Boolean(embeddedTermUri);

  const [details, setDetails] = useState();
  const [breadcrumbTerms, setBreadcrumbTerms] = useState([]);

  const scrollRef = useRef();

  useEffect(() => {
    let isSubscribed = true;
    setDetails(null);

    // If term display is not on the screen, scroll up to it.
    if (scrollRef.current.getBoundingClientRect().top < 0) {
      // Enable smooth scrolling unless prefers-reduced-motion enabled.
      const behavior = window.matchMedia("(prefers-reduced-motion: reduce)")
        .matches
        ? "auto"
        : "smooth";
      scrollRef.current.scrollIntoView({ behavior });
    }

    fetch(`/api/getConceptDetails?${new URLSearchParams({ uriOrId: termId })}`)
      .then((resp) => resp.json())
      .then((data) => {
        if (isSubscribed) {
          setDetails(data);
        }
      });
    return () => {
      isSubscribed = false;
    };
  }, [termId]);

  useEffect(() => {
    let isSubscribed = true;
    setBreadcrumbTerms([]);
    const url = `/api/getConceptParents?${new URLSearchParams({
      uriOrId: termId,
    })}`;
    fetch(url)
      .then((resp) => resp.json())
      .then((data) => {
        if (isSubscribed) {
          setBreadcrumbTerms(data);
        }
      });
    return () => {
      isSubscribed = false;
    };
  }, [termId]);

  return (
    <div ref={scrollRef}>
      {details ? (
        <Header>
          {details.prefLabel}{" "}
          {isEmbedded && (
            <AddToRecordButton
              termUri={embeddedTermUri}
              termLabel={details.prefLabel}
            />
          )}
        </Header>
      ) : (
        <em>Loading...</em>
      )}

      {breadcrumbTerms.length ? (
        <BreadcrumbBar
          terms={breadcrumbTerms}
          setEmbeddedTermUri={setEmbeddedTermUri}
        />
      ) : (
        details && <em>Loading breadcrumbs...</em>
      )}
      {details ? (
        <TermDetail
          uri={details.uri}
          source={details.source}
          definitions={details.definitions}
          broader={details.broader}
          narrower={details.narrower}
          related={details.related}
          altLabels={details.altLabels}
          scopeNotes={details.scopeNotes}
          created={details.created}
          modified={details.modified}
          setEmbeddedTermUri={setEmbeddedTermUri}
        />
      ) : null}
    </div>
  );
}

TermDisplay.propTypes = {
  embeddedTermUri: PropTypes.string,
  setEmbeddedTermUri: PropTypes.func,
};

TermDisplay.defaultProps = {
  embeddedTermUri: null,
  setEmbeddedTermUri: null,
};

export default TermDisplay;
