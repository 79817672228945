import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BsSearch } from "react-icons/bs";
import { extractTermId } from "./utils";
import SidebarContainer from "./SidebarContainer";

const SearchContainer = styled.div`
  padding: 0.5rem;
  box-sizing: border-box;
`;

const SearchBox = styled.div`
  padding: 0.25rem;
  border: 1px solid #c4c4c4;
  form {
    display: flex;
  }

  input[type="search"] {
    width: 100%;
    padding: 0.75rem;
    border: none;
    font-size: var(--type-size-medium);
    ::placeholder {
      position: relative;
      font-style: italic;
      color: #999999;
      font-size: var(--type-size-smaller);
    }
    :focus {
      outline: 2px solid var(--color-academies-blue);
    }
  }

  button[type="submit"] {
    border: none;
    color: white;
    background-color: var(--color-academies-blue);
    width: 2.5rem;
    padding: 0;
    outline: 2px solid var(--color-academies-blue);
  }

  svg {
    height: 1rem;
    width: 1rem;
  }
`;

const ResultsBox = styled.div`
  position: relative;
  ul {
    background-color: rgba(244, 244, 244);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    margin: 0;
    display: block;
    list-style: none;
    position: absolute;
    padding-inline-start: 0;
    width: 100%;
  }

  li {
    padding: 0.5rem;
    &:hover {
      background-color: #e5e5e5;
    }
    &.selected {
      background: #e5e5e5;
    }
    > span {
      font-style: italic;
      color: grey;
    }
    > span > em {
      background-color: var(--color-nas-gold);
    }
    user-select: none;
  }
`;

const numResults = 5;
const minSearchTermChars = 3;

function Search(props) {
  const { setEmbeddedTermUri, setEmbeddedSearchTerm } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [stateMessage, setStateMessage] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [resultClicked, setResultClicked] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const history = useHistory();

  const params = new URLSearchParams(useLocation().search);
  const paramsTerm = params.get("term");

  useEffect(() => {
    let isSubscribed = true;
    const queryContainsBoolean = !!searchTerm
      .split(" ")
      .filter((token) => ["AND", "OR"].includes(token)).length;

    if (queryContainsBoolean) {
      setStateMessage(
        "To see results for an AND/OR query, click the search icon or press Enter."
      );
      setResults([]);
    } else if (searchActive && searchTerm.length >= minSearchTermChars) {
      const url = `/api/getAutocompleteResults?${new URLSearchParams({
        text: searchTerm,
        numResults,
      })}`;
      setStateMessage("Loading...");
      setResults([]);
      fetch(url)
        .then((resp) => resp.json())
        .then((data) => {
          if (isSubscribed) {
            const exactMatches = data.filter(
              (result) => result.label.toLowerCase() === searchTerm
            );
            const otherResults = data
              .filter((result) => !exactMatches.includes(result))
              .sort((a, b) =>
                a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
              );

            setResults(exactMatches.concat(otherResults).slice(0, numResults));
            setStateMessage(data.length ? "" : "No suggested results.");
            setSelectedIndex(-1);
          }
        })
        .catch(() => {
          setStateMessage("Error: Unable to retrieve suggestions.");
        });
    } else {
      setResults([]);
      setStateMessage("");
    }
    return () => {
      isSubscribed = false;
    };
  }, [searchTerm]);

  function handleSubmit(e) {
    e.preventDefault();
    setSearchActive(false);
    setStateMessage("");
    if (setEmbeddedSearchTerm) {
      setEmbeddedTermUri("");
      setEmbeddedSearchTerm(searchTerm);
    } else {
      history.push({
        pathname: "/search",
        search: `?${new URLSearchParams({ term: searchTerm })}`,
      });
    }
  }

  function goToResultPage(result) {
    setSearchActive(false);
    setSelectedIndex(-1);
    setSearchTerm(result.label);
    if (setEmbeddedTermUri) {
      setEmbeddedTermUri(result.uri);
    } else {
      const termId = extractTermId(result.uri);
      history.push({
        pathname: `/term/${termId}`,
      });
    }
  }

  return (
    <SidebarContainer headerText="Search" HeaderIcon={BsSearch}>
      <SearchContainer>
        <SearchBox>
          <form onSubmit={handleSubmit}>
            <input
              type="search"
              placeholder="Begin typing. You may use AND and OR"
              onChange={(e) => {
                setSearchActive(true);
                setSearchTerm(e.target.value);
              }}
              minLength={minSearchTermChars}
              onFocus={() => setSearchActive(true)}
              onBlur={() => {
                if (!resultClicked) {
                  setSelectedIndex(-1);
                  setSearchActive(false);
                }
                setResultClicked(false);
              }}
              onKeyDown={(event) => {
                if (results.length && event.key === "ArrowDown") {
                  setSearchActive(true);
                  if (selectedIndex < results.length - 1) {
                    setSelectedIndex(
                      selectedIndex >= 0 ? selectedIndex + 1 : 0
                    );
                  }
                } else if (results.length && event.key === "ArrowUp") {
                  setSelectedIndex(selectedIndex >= 0 ? selectedIndex - 1 : -1);
                } else if (event.key === "Enter" && selectedIndex >= 0) {
                  event.preventDefault();
                  const result = results[selectedIndex];
                  goToResultPage(result);
                }
              }}
              value={searchTerm !== null ? searchTerm : paramsTerm}
            />
            <button type="submit">
              <BsSearch />
            </button>
          </form>
        </SearchBox>

        <ResultsBox>
          {searchActive && stateMessage ? <em>{stateMessage}</em> : null}

          {results.length && searchActive ? (
            <ul>
              {results.map((result, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <li
                  role="option"
                  onMouseDown={() => {
                    setResultClicked(true);
                    setSelectedIndex(index);
                  }}
                  onClick={() => {
                    goToResultPage(result);
                  }}
                  key={result.uri}
                  className={index === selectedIndex ? "selected" : null}
                  aria-selected={index === selectedIndex ? "true" : "false"}
                >
                  {result.label}
                  {result.useFor && <span> USE FOR {result.useFor}</span>}
                </li>
              ))}
            </ul>
          ) : null}
        </ResultsBox>
      </SearchContainer>
    </SidebarContainer>
  );
}

Search.propTypes = {
  setEmbeddedTermUri: PropTypes.func,
  setEmbeddedSearchTerm: PropTypes.func,
};

Search.defaultProps = {
  setEmbeddedTermUri: null,
  setEmbeddedSearchTerm: null,
};

export default Search;
