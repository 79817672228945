import React, { useState } from "react";
import styled from "styled-components";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import ReCaptchaV2 from "react-google-recaptcha";
import { TextInput, SubmitButton, CaptchaContainer } from "./FormElements";
import StatusMessage from "./StatusMessage";
import { useInput } from "./utils";

const TermContainer = styled.div`
  padding: 0 0.5rem;
  border-bottom: 1px solid lightgrey;
  margin: 1rem 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  gap: 0.5rem;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  color: white;
  text-align: left;
  font-size: var(--type-size-smaller);
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
  gap: 0.5rem;
  background: var(--color-nae-blue);
  
  &.remove {
    background: var(--color-red-brick);
  }
  svg {
    font-size: 1rem;
    color: white;
  }
  
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
    svg {
      color: #c4c4c4;
  }
`;

const maxTerms = 5;
const termTemplate = { term: "", note: "" };
Object.freeze(termTemplate);

function TermSuggestion() {
  gtag('event', 'page_view');
  const [name, setName] = useInput("");
  const [emailAddress, setEmailAddress] = useInput("");
  const [affiliation, setAffiliation] = useInput("");
  const [terms, setTerms] = useState([{ ...termTemplate }]);

  const recaptchaRef = React.useRef();
  const [captchaToken, setCaptchaToken] = useState("");

  // array of status message and type
  const [status, setStatus] = useState([]);

  function handleSubmit(e) {
    e.preventDefault();
    fetch("/api/submitSuggestion", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        emailAddress,
        affiliation,
        terms: terms.filter((term) => term.term),
        captchaToken,
      }),
    })
      .then((resp) => resp.text())
      .then((text) => {
        if (text === "Captcha code invalid.") {
          setStatus([
            "Unable to send messsage: captcha code invalid.",
            "error",
          ]);
        } else if (text === "Suggestion submitted.") {
          const successMessage = `Thank you for your term suggestion${
            terms.length > 1 ? "s" : ""
          }.  We appreciate your input. The TRT Subcommittee will consider your proposed term${
            terms.length > 1 ? "s" : ""
          } at a future meeting.`;
          setStatus([successMessage, "success"]);
          e.target.reset();
        } else {
          setStatus(["Error: unable to send term suggestion.", "error"]);
        }
      })
      .catch(() => {
        setStatus(["Error: unable to verify captcha code.", "error"]);
      });
    setCaptchaToken("");
    recaptchaRef.current.reset();
  }

  return (
    <div>
      <h2>Suggest New TRT Terms</h2>
      <p>
        Use the form below to suggest up to 5 new terms for inclusion in the
        Transportation Research Thesaurus. Use the comment box to include any
        information supporting the request for the new term such as a citation
        or source and to recommend where the new term should appear in the
        hierarchy.
      </p>
      <br />
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name:</label>
        <TextInput
          name="name"
          placeholder="Your name"
          onChange={setName}
          required
        />

        <label htmlFor="email">Email:</label>
        <TextInput
          type="email"
          name="email"
          placeholder="Your contact email address"
          onChange={setEmailAddress}
          required
        />

        <label htmlFor="affiliation">Affiliation:</label>
        <TextInput
          name="affiliation"
          onChange={setAffiliation}
          placeholder="Your agency, school, company or organization"
        />
        {terms.map((term, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`term-${index}`}>
            <h4>Suggested Term {index + 1}</h4>
            <TermContainer>
              <label htmlFor="term-name">
                Term Name:
                <TextInput
                  name="term-name"
                  onChange={(e) => {
                    const newTerms = [...terms];
                    newTerms[index] = { ...term, term: e.target.value };
                    setTerms(newTerms);
                  }}
                  placeholder="Your suggested term"
                />
              </label>

              <label htmlFor="term-notes">
                Note (optional):
                <TextInput
                  as="textarea"
                  name="term-notes"
                  onChange={(e) => {
                    const newTerms = [...terms];
                    newTerms[index] = { ...term, note: e.target.value };
                    setTerms(newTerms);
                  }}
                  placeholder="Note about this suggested term"
                />
              </label>
            </TermContainer>
          </div>
        ))}
        <ButtonContainer>
          <Button
            type="button"
            onClick={() => {
              setTerms(terms.concat({ ...termTemplate }));
            }}
            disabled={terms.length >= maxTerms}
          >
            <FaPlusCircle />
            Suggest Another Term
          </Button>

          <Button
            type="button"
            className="remove"
            onClick={() => {
              setTerms(terms.slice(0, -1));
            }}
            disabled={terms.length <= 1}
          >
            <FaMinusCircle /> Remove Suggestion
          </Button>
        </ButtonContainer>
        <CaptchaContainer>
          <ReCaptchaV2
            sitekey={process.env.RECAPTCHA_SITE_KEY}
            ref={recaptchaRef}
            onChange={(token) => {
              setCaptchaToken(token);
            }}
            onExpired={() => setCaptchaToken(null)}
          />
        </CaptchaContainer>
        <SubmitButton
          type="submit"
          value="Submit Suggestion"
          disabled={[
            name,
            emailAddress,
            affiliation,
            captchaToken,
            terms.some((term) => term.term),
          ].some((elem) => !elem)}
        />
      </form>
      {status.length ? (
        <StatusMessage message={status[0]} type={status[1]} />
      ) : null}
    </div>
  );
}

export default TermSuggestion;
