import React from "react";
import ReactSelect, { components } from "react-select";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
// eslint-disable-next-line import/no-extraneous-dependencies
import { CacheProvider } from "@emotion/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import createCache from "@emotion/cache";
import PropTypes from "prop-types";
import styled from "styled-components";

// Used with the CacheProvider to apply a nonce to react-select emotion style tags
const cache = createCache({
  // eslint-disable-next-line no-underscore-dangle
  nonce: window.__webpack_nonce__,
  key: "trt-select",
});

const StyledSelect = styled(ReactSelect)`
  & .React-Select__control {
    box-shadow: 2px 2px 4px 0px rgba(196, 196, 196, 0.75);
    border: 0.5px solid rgba(220, 220, 220, 1);
    border-radius: 10px;
    width: 100%;
  }

  & .React-Select__value-container {
    display: flex;

    .React-Select__single-value {
      position: relative;
      transform: none;
    }
  }

  & .React-Select__menu {
    border-radius: 10px;
    top: 85%;
  }
  & .React-Select__indicator-separator {
    background: none;
  }
  & .React-Select__dropdown-indicator {
    color: black;
    background-color: lightgrey;
    border-radius: 50%;
    padding: 0.1rem;
    margin-right: 0.5rem;
  }

  // both of the below are required to
  & .React-Select__option--is-focused {
    background-color: var(--color-academies-blue);
    color: white;

    :active {
      background-color: var(--color-academies-blue);
    }
  }
  & .React-Select__option--is-selected {
    background-color: var(--color-nae-blue);
  }
`;

const DropdownIndicator = (props) => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      {!menuIsOpen && <BiCaretDown />}
      {menuIsOpen && <BiCaretUp />}
    </components.DropdownIndicator>
  );
};

DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool.isRequired,
  }).isRequired,
};

function Select(props) {
  const { onChange, options, placeholder, value } = props;
  return (
    <CacheProvider value={cache}>
      <StyledSelect
        classNamePrefix="React-Select"
        components={{ DropdownIndicator }}
        onChange={onChange}
        options={options}
        value={options.filter((option) => option.value === value)[0]}
        placeholder={placeholder}
      />
    </CacheProvider>
  );
}

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default Select;
