import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { extractTermId } from "./utils";

const Link = styled.a`
  color: var(--color-academies-blue);
  :visited {
    color: var(--color-academies-blue);
  }
  &.active {
    font-weight: bold;
  }
`;

function TermLink(props) {
  const { uri, label, setEmbeddedTermUri } = props;

  if (setEmbeddedTermUri) {
    return (
      <Link
        href="/embed"
        onClick={(e) => {
          e.preventDefault();
          setEmbeddedTermUri(uri);
        }}
      >
        {label}
      </Link>
    );
  }

  return (
    <Link as={NavLink} to={`/term/${extractTermId(uri)}`}>
      {label}
    </Link>
  );
}

TermLink.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  uri: PropTypes.string.isRequired,
  setEmbeddedTermUri: PropTypes.func,
};

TermLink.defaultProps = {
  setEmbeddedTermUri: null,
};

export default TermLink;
