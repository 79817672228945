const formats = {
  N3: "N3",
  BinaryRDF: "Binary RDF",
  RdfXml: "RDF XML",
  CSV: "CSV",
  Excel: "Excel",
};

Object.freeze(formats);

export default formats;
