import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { FaSpinner } from "react-icons/fa";
import { BsDownload } from "react-icons/bs";
import download from "downloadjs";
import TermLink from "./TermLink";
import formats from "./download/formats";
import Select from "./download/Select";

const Description = styled.p`
  font-size: var(--type-size-smaller);
  overflow-wrap: break-word;
`;

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 1em;
  padding-top: 1rem;
`;

const Column = styled.div`
  width: 30%;
  @media (max-width: 576px) {
    width: 47%;
  }
  h5 {
    font-size: var(--type-size-smaller);
    margin: 0;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    font-size: var(--type-size-smaller);
    padding-top: 0.25rem;
  }
  a {
    color: var(--color-academies-blue);
    :visited {
      color: var(--color-academies-blue);
    }
  }
`;

const DescriptionList = styled.dl`
  padding-top: 1rem;
  font-size: var(--type-size-small);
  div {
    padding-bottom: 0.5rem;
  }
  dt {
    display: inline;
    font-style: italic;
  }
  dd {
    display: inline;
    margin-inline-start: 0;
  }
`;

const DownloadArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DownloadButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--type-size-medium);
  background-color: var(--color-nae-blue);
  color: white;
  border: none;
  height: 2rem;
  margin-left: 1rem;
  border-radius: 5px;
  :disabled {
    background-color: #c4c4c4;
  }

  svg {
    margin-left: 0.5em;
  }
`;

const SpinnerAnimation = keyframes`
0% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(359deg);
          transform: rotate(359deg);
}
`;

const DownloadSpinner = styled(FaSpinner)`
  -webkit-animation: ${SpinnerAnimation} 2s infinite linear;
  animation: ${SpinnerAnimation} 2s infinite linear;
`;

function TermDetail(props) {
  const {
    uri,
    source,
    definitions,
    broader,
    narrower,
    related,
    altLabels,
    scopeNotes,
    setEmbeddedTermUri,
  } = props;
  const [downloadFormat, setDownloadFormat] = useState("Excel");
  const [isDownloading, setIsDownloading] = useState(false);

  function doDownload(downloadLink) {
    if (!isDownloading) {
      setIsDownloading(true);
      let filename;
      fetch(downloadLink)
        .then((response) => {
          if (response.status === 200) {
            filename = response.headers.get("content-disposition");
            [, filename] = filename.split("filename=");
            return response.blob();
          }
          return "";
        })
        .then((body) => {
          download(body, filename, "application/octet-stream");
          setIsDownloading(false);
        });
    }
  }

  let { created } = props;

  created = new Date(created).toLocaleDateString();

  const getTermLink = (term) => (
    <TermLink
      uri={term.uri}
      label={term.prefLabel}
      setEmbeddedTermUri={setEmbeddedTermUri}
    />
  );

  const relatedLinks = [
    {
      label: "RIP",
      url: `https://rip.trb.org/Results?${new URLSearchParams({
        specificTerms: uri,
        q: "",
      })}`,
    },
    {
      label: "TRID",
      url: `https://trid.trb.org/Results?${new URLSearchParams({
        specificTerms: uri,
        q: "",
      })}`,
    },
    {
      label: "PubsIndex",
      url: `https://pubsindex.trb.org/results.aspx?${new URLSearchParams({
        specificTerms: uri,
      })}`,
    },
  ].map((link) => (
    // Open external links in new tab if using embedded view.
    <li key={link.url}>
      {setEmbeddedTermUri ? (
        <a target="_blank" rel="noreferrer" href={link.url}>
          {link.label}
        </a>
      ) : (
        <a href={link.url}>{link.label}</a>
      )}
    </li>
  ));

  const alphaTermCompareFn = (a, b) =>
    a.prefLabel.toLowerCase() > b.prefLabel.toLowerCase() ? 1 : -1;

  return (
    <div>
      {definitions[0] && (
        <Description>
          {definitions[0]} {source && <>(Source: {source})</>}
        </Description>
      )}
      <Columns>
        {altLabels.length ? (
          <Column>
            <h5>Use For</h5>
            <ul>
              {altLabels.sort().map((label) => (
                <li key={label}>{label}</li>
              ))}
            </ul>
          </Column>
        ) : null}
        {broader ? (
          <Column>
            <h5>Broader Term</h5>
            <ul>
              <li>{getTermLink(broader)}</li>
            </ul>
          </Column>
        ) : null}
        {narrower.length ? (
          <Column>
            <h5>Narrower Terms</h5>
            <ul>
              {narrower.sort(alphaTermCompareFn).map((term) => (
                <li key={term.uri}>{getTermLink(term)}</li>
              ))}
            </ul>
          </Column>
        ) : null}
        {related.length ? (
          <Column>
            <h5>Related Terms</h5>
            <ul>
              {related.sort(alphaTermCompareFn).map((term) => (
                <li key={term.uri}>{getTermLink(term)}</li>
              ))}
            </ul>
          </Column>
        ) : null}
        <Column>
          <h5>Find Related Records</h5>
          <ul>{relatedLinks}</ul>
        </Column>
      </Columns>
      <DescriptionList>
        {scopeNotes.length ? (
          <div>
            <dt>Scope Notes:</dt>
            <dd> {scopeNotes[0]}</dd>
          </div>
        ) : null}
        <div>
          <div>
            <dt>Created:</dt>
            <dd> {created}</dd>
          </div>
        </div>
      </DescriptionList>
      {!setEmbeddedTermUri ? (
        <DownloadArea>
          <Select
            placeholder="Download Format"
            options={Object.entries(formats).map(([key, label]) => ({
              label,
              value: key,
            }))}
            value={downloadFormat}
            onChange={(option) => setDownloadFormat(option.value)}
          />
          <DownloadButton
            type="button"
            onClick={() =>
              doDownload(
                `/download/file?${new URLSearchParams({
                  format: downloadFormat,
                  uri,
                  isSubtreeExport: false,
                })}`
              )
            }
          >
            Download {isDownloading ? <DownloadSpinner /> : <BsDownload />}
          </DownloadButton>
        </DownloadArea>
      ) : null}
    </div>
  );
}

const childConceptProp = PropTypes.arrayOf(
  PropTypes.objectOf(PropTypes.string)
);

TermDetail.propTypes = {
  uri: PropTypes.string.isRequired,
  source: PropTypes.string,
  altLabels: PropTypes.arrayOf(PropTypes.string),
  definitions: PropTypes.arrayOf(PropTypes.string),
  broader: PropTypes.objectOf(PropTypes.string),
  narrower: childConceptProp,
  related: childConceptProp,
  scopeNotes: PropTypes.arrayOf(PropTypes.string),
  created: PropTypes.string,
  setEmbeddedTermUri: PropTypes.func,
};

TermDetail.defaultProps = {
  source: "",
  altLabels: [],
  definitions: [],
  broader: {},
  narrower: [],
  related: [],
  scopeNotes: [],
  created: "",
  setEmbeddedTermUri: null,
};

export default TermDetail;
