import React from "react";
import styled from "styled-components";
import Image from "./images/connected-roads-scaled.jpg";
import Resources from "./Resources";

const HeaderImage = styled.img`
  width: 100%;
  padding-bottom: 2rem;
`;

// Hide Resources when in mobile view. It will then be rendered under Search, Browse etc.
const ResourcesContainer = styled.div`
  padding-top: 2rem;
  @media (max-width: 576px) {
    display: none;
  }
`;

function Home() {
  return (
    <>
      <h2>Welcome to the TRT</h2>
      <HeaderImage src={Image} alt="" />
      <p>
        The Transportation Research Thesaurus (TRT) is a tool to provide a
        common and consistent language between producers and users of
        transportation information. The TRT covers all modes and aspects of
        transportation. Terms are arranged in hierarchies under 21 top concepts.
      </p>
      <p>
        You can search the TRT by keyword, or browse alphabetically and by
        hierarchy. Click on any term to view its relationships (broader,
        narrower, and related terms) and to find records indexed with that term
        in TRB’s TRID, Research in Progress (RIP) and Publications Index
        databases.
      </p>
      <ResourcesContainer>
        <Resources />
      </ResourcesContainer>
    </>
  );
}

export default Home;
