import styled from "styled-components";

const TextInput = styled.input`
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0rem 1rem;
  resize: vertical;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: var(--type-size-medium);
  height: ${(props) => props.height};
  border: 1px solid #c4c4c4;

  ::placeholder {
    font-size: var(--type-size-medium);
    font-size: var(--type-size-smaller);
    color: #999999;
  }
`;

const SubmitButton = styled.input`
  width: 100%;
  font-size: var(--type-size-medium);
  background-color: var(--color-academies-blue);
  color: white;
  border: none;
  height: 2rem;
  border-radius: 5px;
  :disabled {
    background-color: #c4c4c4;
  }
`;

const CaptchaContainer = styled.div`
  padding: 1rem;
`;

export { TextInput, SubmitButton, CaptchaContainer };
