import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SortableTable from "./SortableTable";

const Styles = styled.div`
  display: flex;
  justify-content: center;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      background: var(--color-ocean-blue);
      color: white;
      font-weight: bold;

      svg {
        margin-left: 0.5em;
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid var(--color-pale-background);
      border-right: 1px solid var(--color-pale-background);

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const ExplainerText = styled.p`
  padding-bottom: 1rem;
  font-size: var(--type-size-smaller);
`;

function ViewResults({ downloadMode, monthFrom, yearFrom, topTermUri }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const columns = [
    {
      Header: "Term",
      accessor: "prefLabel",
      // Show the label without - characters when sorting
      Cell: ({ column, row: { original } }) => {
        if (column.isSorted || column.isSortedDesc) {
          return original.label;
        }
        return original.prefLabel;
      },
      // Sort the table by the trimmed label
      sortType: React.useMemo(() => (rowA, rowB) => {
        if (!rowA || !rowB) return 1;
        return rowA.original.label.toLowerCase() >
          rowB.original.label.toLowerCase()
          ? 1
          : -1;
      }),
    },
  ];
  const params = {};

  switch (downloadMode) {
    case "By Top-Level Term":
      params.uri = topTermUri;
      break;
    case "By Date":
      params.date = new Date(yearFrom, monthFrom, 1).toISOString();
      columns.push({
        Header: "Last Modified Date",
        accessor: "modified",
        Cell: ({ row: { original } }) => original.modified.toLocaleDateString(),
      });
      break;
    default:
      break;
  }

  useEffect(() => {
    fetch(`/download/view?${new URLSearchParams(params)}`)
      .then((resp) => resp.json())
      .then((json) => {
        setLoading(false);
        const newData = [...json];
        const dashRegex = new RegExp("^[-]*\\s");
        newData.forEach((row, index) => {
          if (downloadMode === "By Date") {
            newData[index].label = row.prefLabel;
          } else {
            newData[index].label = row.prefLabel.replace(dashRegex, "");
          }
          newData[index].modified = new Date(row.modified);
        });
        setData(newData);
      });
  }, []);

  if (loading) {
    return "Loading...";
  }

  if (!loading && data.count === 0) {
    return "No Results Found.";
  }

  return (
    <>
      {["By Top-Level Term", "Entire TRT"].includes(downloadMode) && (
        <ExplainerText>
          Terms are arranged in hierarchical order. Each “-” represents a lower
          level of the hierarchy.
        </ExplainerText>
      )}
      <Styles>
        <SortableTable columns={columns} data={data} />
      </Styles>
    </>
  );
}

ViewResults.propTypes = {
  downloadMode: PropTypes.string.isRequired,
  monthFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  yearFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  topTermUri: PropTypes.string.isRequired,
};

export default ViewResults;
