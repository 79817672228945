import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ResultTree from "./ResultTree";

const Container = styled.div`
  overflow: auto;
  padding: 0.5rem;
`;

function Hierarchical(props) {
  const { setEmbeddedTermUri } = props;
  return (
    <Container>
      <ResultTree setEmbeddedTermUri={setEmbeddedTermUri} label="Top Terms" />
    </Container>
  );
}

Hierarchical.propTypes = {
  setEmbeddedTermUri: PropTypes.func,
};

Hierarchical.defaultProps = {
  setEmbeddedTermUri: null,
};

export default Hierarchical;
