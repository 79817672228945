import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.75px solid var(--color-ocean-blue);
  background: rgba(244, 244, 244, 0.15);
  margin-bottom: 2rem;
  height: ${(p) => p.height};
`;

const Header = styled.div`
  color: white;
  font-size: var(--type-size-larger);
  padding: 0.5rem;
  background-color: var(--color-ocean-blue);
  display: inline-flex;

  span {
    padding: 0 0.5rem;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
    color: white;
  }
`;

function SidebarContainer(props) {
  const { height, children, HeaderIcon, headerText } = props;
  return (
    <ContainerDiv height={height}>
      <Header>
        <span>{headerText} </span>
        <HeaderIcon />
      </Header>
      {children}
    </ContainerDiv>
  );
}

SidebarContainer.propTypes = {
  headerText: PropTypes.string.isRequired,
  HeaderIcon: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  height: PropTypes.string,
};

SidebarContainer.defaultProps = {
  height: null,
};

export default SidebarContainer;
