import React, { useState } from "react";
import styled from "styled-components";
import Search from "./Search";
import SearchResults from "./SearchResults";
import TermDisplay from "./TermDisplay";
import Browse from "./browse/Browse";

const Container = styled.div`
  padding: 1rem;
  padding-top: 0;
`;

const FixedSearch = styled.div`
  position: sticky;
  top: 0;
  padding-top: 1rem;
  width: 100%;
  background: white;
`;

function EmbeddableView() {
  const [termUri, setTermUri] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <Container>
      <FixedSearch>
        <h2>Transportation Research Thesaurus (TRT)</h2>
        <Search
          setEmbeddedTermUri={setTermUri}
          setEmbeddedSearchTerm={setSearchTerm}
        />
      </FixedSearch>

      {termUri && (
        <TermDisplay
          embeddedTermUri={termUri}
          setEmbeddedTermUri={setTermUri}
        />
      )}
      {searchTerm && !termUri && (
        <SearchResults
          embeddedSearchTerm={searchTerm}
          setEmbeddedTermUri={setTermUri}
        />
      )}
      <Browse setEmbeddedTermUri={setTermUri} />
    </Container>
  );
}

export default EmbeddableView;
