import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TermLink from "./TermLink";

const StyledOl = styled.ol`
  list-style-type: none;
  padding-inline-start: 0;
  padding-bottom: 0.5rem;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    font-style: italic;
    font-size: var(--type-size-medium);
    color: grey;
    padding-right: 0.25rem;
  }

  li.term::after {
    content: " >";
  }

  li.final {
    color: #767676;
    font-weight: bold;
  }
`;

function BreadcrumbBar(props) {
  const { terms, setEmbeddedTermUri } = props;
  const parentTerms = terms.slice(0, terms.length - 1);
  const thisTerm = terms[terms.length - 1];

  return (
    <StyledOl>
      <li className="term">Top Terms</li>
      {parentTerms.map((term) => (
        <TermLink
          uri={term.uri}
          key={term.uri}
          label={<li className="term">{term.prefLabel}</li>}
          setEmbeddedTermUri={setEmbeddedTermUri}
        />
      ))}
      <li className="final">{thisTerm.prefLabel}</li>
    </StyledOl>
  );
}

BreadcrumbBar.propTypes = {
  terms: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  setEmbeddedTermUri: PropTypes.func,
};

BreadcrumbBar.defaultProps = {
  setEmbeddedTermUri: null,
};

export default BreadcrumbBar;
