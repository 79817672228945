const paths = {
  home: "/",
  hotTopicTerms: "/hot-topic-terms",
  howToUse: "/how-to-use",
  suggestTerms: "/suggest-terms",
  contactTrb: "/contact",
  download: "/download",
  embed: "/embed",
  about: "/about-the-trt",
};

Object.freeze(paths);

export default paths;
