import React, { useState } from "react";
import ReCaptchaV2 from "react-google-recaptcha";
import { TextInput, SubmitButton, CaptchaContainer } from "./FormElements";
import StatusMessage from "./StatusMessage";
import { useInput } from "./utils";

function Contact() {
  const [name, setName] = useInput();
  const [emailAddress, setEmailAddress] = useInput();
  const [affiliation, setAffiliation] = useInput();
  const [message, setMessage] = useInput();

  const recaptchaRef = React.useRef();
  const [captchaToken, setCaptchaToken] = useState("");

  // array of status message and type
  const [status, setStatus] = useState([]);

  function handleSubmit(e) {
    e.preventDefault();
    fetch("/api/submitContactUs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        emailAddress,
        affiliation,
        text: message,
        captchaToken,
      }),
    })
      .then((resp) => resp.text())
      .then((text) => {
        if (text === "Captcha code invalid.") {
          setStatus([
            "Unable to send messsage: captcha code invalid.",
            "error",
          ]);
        } else if (text === "Message sent.") {
          setStatus([
            "Thank you for your email. We will respond as soon as possible.",
            "success",
          ]);
          e.target.reset();
        } else {
          setStatus(["Error: unable to send message.", "error"]);
        }
      })
      .catch(() => {
        setStatus(["Error: unable to verify captcha code.", "error"]);
      });
    setCaptchaToken("");
    recaptchaRef.current.reset();
  }

  return (
    <div>
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name:</label>
        <TextInput
          name="name"
          placeholder="Your name"
          onChange={setName}
          required
        />

        <label htmlFor="email">Email:</label>
        <TextInput
          type="email"
          name="email"
          placeholder="Your contact email address"
          onChange={setEmailAddress}
          required
        />

        <label htmlFor="affiliation">Affiliation (optional):</label>
        <TextInput
          name="affiliation"
          onChange={setAffiliation}
          placeholder="Your agency, school, company or organization"
        />

        <label htmlFor="message">Message:</label>
        <TextInput
          name="message"
          placeholder="Your message to us"
          as="textarea"
          height="8rem"
          onChange={setMessage}
          required
        />
        <CaptchaContainer>
          <ReCaptchaV2
            sitekey={process.env.RECAPTCHA_SITE_KEY}
            ref={recaptchaRef}
            onChange={(token) => {
              setCaptchaToken(token);
            }}
            onExpired={() => setCaptchaToken(null)}
          />
        </CaptchaContainer>
        <SubmitButton
          type="submit"
          value="Send Message"
          disabled={[name, emailAddress, message, captchaToken].some(
            (elem) => !elem
          )}
        />
        {status.length ? (
          <StatusMessage message={status[0]} type={status[1]} />
        ) : null}
      </form>
    </div>
  );
}

export default Contact;
