import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import alphaOptions from "./alphaOptions";
import TermLink from "../TermLink";

const AlphaList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding-inline-start: 0;
  padding: 0.5rem 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  column-gap: 0.25rem;

  button {
    padding: 0.5rem;
    margin: 0;
    background: none;
    border: none;
    &.active {
      color: var(--color-academies-blue);
      font-weight: bold;
    }
    &:hover {
      color: var(--color-academies-blue);
      cursor: pointer;
    }
    font-size: var(--type-size-medium);
  }
`;

const TermList = styled.ul`
  margin: unset;
  padding-left: 1.5rem;
  overflow-y: auto;
  list-style: none;
  border-top: 2px solid rgba(150, 150, 150, 0.5);
  ::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(196, 196, 196, 0.25);
  }
  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 2px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #848484;
  }

  li {
    padding: 0.25rem 0;
    @media (max-width: 768px) {
      padding: 0.5rem 0;
    }
    > em {
      color: grey;
      padding-left: 1rem;
    }
  }
`;

function Alphabetical(props) {
  const { setEmbeddedTermUri } = props;
  const [alphaInput, setAlphaInput] = useState("A");
  const [concepts, setConcepts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setConcepts([]);
    setLoading(true);
    const url = `/api/getConceptsByInput?${new URLSearchParams({
      input: alphaInput.split("").join("|"),
    })}`;
    fetch(url)
      .then((resp) => resp.json())
      .then((data) => {
        setLoading(false);
        setConcepts(
          data.sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
          )
        );
      });
  }, [alphaInput]);

  return (
    <>
      <AlphaList>
        {alphaOptions.map((option) => (
          <li key={option}>
            <button
              className={alphaInput === option ? "active" : null}
              type="button"
              onClick={() => setAlphaInput(option)}
            >
              {option}
            </button>
          </li>
        ))}
      </AlphaList>
      <TermList>
        {loading ? (
          <span>Loading...</span>
        ) : (
          concepts.map((concept) => (
            <li key={`${concept.uri}:${concept.label}`}>
              <TermLink
                uri={concept.uri}
                label={concept.label}
                setEmbeddedTermUri={setEmbeddedTermUri}
              />
              {concept.useFor && <em> Use {concept.useFor} </em>}
            </li>
          ))
        )}
      </TermList>
    </>
  );
}

Alphabetical.propTypes = {
  setEmbeddedTermUri: PropTypes.func,
};

Alphabetical.defaultProps = {
  setEmbeddedTermUri: null,
};

export default Alphabetical;
