import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FaCaretSquareUp, FaCaretSquareDown } from "react-icons/fa";
import TermLink from "../TermLink";

const ChildList = styled.ul`
  list-style: none;
  padding-inline-start: 0.75rem;
  margin: 0;

  li {
    padding: 0.25rem 0;
  }

  .child-terms {
    border-left: 1px solid var(--color-grey-background);
  }

  @media (max-width: 768px) {
    li {
      padding: 0.5rem 0;
    }
  }
`;

const Term = styled.div`
  display: flex;
  min-height: 1.5rem;
  align-items: stretch;

  svg {
    height: 1.25rem;
    width: 1.25rem;
  }
  .num-narrowers {
    color: grey;
  }
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding-left: 0.25rem;
`;

function ResultTree(props) {
  const { uri, label, narrowers, setEmbeddedTermUri } = props;
  const isTopTerms = label === "Top Terms";

  const [expanded, setExpanded] = useState(isTopTerms);
  const [loading, setLoading] = useState(false);
  const [children, setChildren] = useState([]);

  useEffect(() => {
    if (expanded && !children.length) {
      setLoading(true);
      const url = `/api/getChildConcepts${
        !isTopTerms ? `?${new URLSearchParams({ uri })}` : ""
      }`;
      fetch(url)
        .then((resp) => resp.json())
        .then((data) => {
          setLoading(false);
          setChildren(
            data.sort((a, b) =>
              a.prefLabel.toLowerCase() > b.prefLabel.toLowerCase() ? 1 : -1
            )
          );
        });
    }
  }, [expanded]);

  return (
    <>
      {!isTopTerms && (
        <Term>
          <span>
            <TermLink
              uri={uri}
              label={label}
              setEmbeddedTermUri={setEmbeddedTermUri}
            />
            &nbsp;
            {narrowers.length ? (
              <span className="num-narrowers">({narrowers.length})</span>
            ) : null}
          </span>
          {narrowers.length ? (
            <ExpandButton type="button" onClick={() => setExpanded(!expanded)}>
              {expanded ? (
                <FaCaretSquareUp color="var(--color-academies-blue)" />
              ) : (
                <FaCaretSquareDown color="grey" />
              )}
            </ExpandButton>
          ) : null}
        </Term>
      )}

      <ChildList className={!isTopTerms ? "child-terms" : null}>
        {loading && <li>Loading child concepts...</li>}
        {expanded &&
          children.map((concept) => (
            <li key={concept.uri}>
              <ResultTree
                uri={concept.uri}
                label={concept.prefLabel}
                narrowers={concept.narrowers ? concept.narrowers : []}
                setEmbeddedTermUri={setEmbeddedTermUri}
              />
            </li>
          ))}
      </ChildList>
    </>
  );
}

ResultTree.propTypes = {
  uri: PropTypes.string,
  label: PropTypes.string.isRequired,
  narrowers: PropTypes.arrayOf(PropTypes.string),
  setEmbeddedTermUri: PropTypes.func,
};

ResultTree.defaultProps = {
  uri: null,
  narrowers: [],
  setEmbeddedTermUri: null,
};

export default ResultTree;
