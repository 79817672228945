import React from "react";
import styled from "styled-components";

const Link = styled.a`
  color: var(--color-academies-blue);
  font-weight: bold;
`;

const Links = styled.ul`
  margin: 0;
  line-height: 2;
  li {
    list-style: none;
  }
`;

function AboutTRT() {
  gtag('event', 'page_view');
  return (
    <>
      <h2>About the TRT</h2>
      <p>
        The Transportation Research Thesaurus (TRT) was initially developed
        under{" "}
        <Link href="https://apps.trb.org/cmsfeed/TRBNetProjectDisplay.asp?ProjectID=573">
          NCHRP Project 20-32
        </Link>{" "}
        to provide a tool to improve the indexing and retrieval of
        transportation information. The thesaurus covers all modes and aspects
        of transportation. A primary use is to provide a common and consistent
        language between producers and users of the{" "}
        <Link href="https://trid.trb.org/">
          Transportation Research Information Services (TRIS)
        </Link>{" "}
        Database. The thesaurus is currently also used as an indexing tool for
        federal, state and university collections. Through the use of the TRT’s
        standardized vocabulary, the transportation community’s access to
        information has been improved.
      </p>
      <p>
        The TRT currently has over 12,000 terms: approximately 9,500 preferred
        terms and 2,500 non-preferred (i.e., lead-in) terms. The terms are
        arranged in hierarchies under 21 top-level terms. The TRT is regularly updated to ensure it reflects current terminology in the transportation sector. The terms are vetted by a committee of information professionals from various transportation domains.
      </p>
      <p>
        For further information on the development of Transportation Research
        Thesaurus see{" "}
        <Link href="http://onlinepubs.trb.org/onlinepubs/nchrp/nchrp_rpt_450.pdf">
          {" "}
          NCHRP Report 450 - Transportation Research Thesaurus and User Guide
        </Link>
        . In 2016, a review of the TRT was launched.{" "}
        <Link href="http://www.trb.org/Main/Blurbs/177476.aspx">
          {" "}
          NCHRP Research Report 874 - The Transportation Research Thesaurus:
          Capabilities and Enhancements
        </Link>{" "}
        documents the first phase of{" "}
        <Link href="https://apps.trb.org/cmsfeed/TRBNetProjectDisplay.asp?ProjectID=4061">
          NCHRP 20-109
        </Link>
        , which entailed a comprehensive assessment of the TRT’s capabilities
        and strategies for the TRT’s future development. The TRT website was
        redesigned as a Phase II activity of this project.
      </p>
      <p>
        Three short video tutorials on how and why to use the TRT are also
        available:{" "}
      </p>
      <Links>
        <li>
          <Link href="https://vimeo.com/266159036">
            Why Use the Transportation Research Thesaurus?
          </Link>
        </li>

        <li>
          <Link href="https://vimeo.com/266158951">
            Super Searching with the Transportation Research Thesaurus
          </Link>
        </li>

        <li>
          <Link href="https://vimeo.com/496881977">
            Keywords for the Technical Report Documentation Page: Let the TRT
            Help
          </Link>
        </li>
      </Links>
      <p>
        For more information about the TRT, or any of the TRIS Databases, please
        visit our{" "}
        <Link href="http://www.trb.org/InformationServices/ResourcesfortheTRISDatabases.aspx">
          {" "}
          Documentation and Training Materials
        </Link>
        .
      </p>
    </>
  );
}

export default AboutTRT;
