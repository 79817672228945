import { useState } from "react";

function extractTermId(uri) {
  return new URL(uri).pathname.split("/")[2];
}

function useInput() {
  const [value, setValue] = useState("");

  function handleChange(event) {
    setValue(event.target.value);
  }
  return [value, handleChange];
}

export { extractTermId, useInput };
