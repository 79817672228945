import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BsStar } from "react-icons/bs";
import SidebarContainer from "./SidebarContainer";
import TermLink from "./TermLink";

const ItemList = styled.ul`
  margin: unset;
  padding-left: 1.5rem;
  overflow-y: auto;
  list-style: none;
  li {
    padding: 0.25rem 0;
    @media (max-width: 768px) {
      padding: 0.5rem 0;
    }
  }
`;

const conceptsToShow = 10;

function RecentlyAdded() {
  const [concepts, setConcepts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = `/api/getRecentlyAddedConcepts?${new URLSearchParams({
      limit: conceptsToShow,
    })}`;
    fetch(url)
      .then((resp) => resp.json())
      .then((data) => {
        setLoading(false);
        setConcepts(data);
      });
  }, []);

  return (
    <SidebarContainer headerText="Recently Added Terms" HeaderIcon={BsStar}>
      <ItemList>
        {loading ? (
          <span>Loading...</span>
        ) : (
          concepts.map((concept) => (
            <li key={`${concept.uri}:${concept.label}`}>
              <TermLink uri={concept.uri} label={concept.label} />
            </li>
          ))
        )}
      </ItemList>
    </SidebarContainer>
  );
}

export default RecentlyAdded;
