import React, { useState } from "react";
import styled from "styled-components";
import { BsEye } from "react-icons/bs";
import PropTypes from "prop-types";
import Alphabetical from "./Alphabetical";
import SidebarContainer from "../SidebarContainer";
import Hierarchical from "./Hierarchical";

const Tabs = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding-inline-start: 0;
  margin: initial;
  border-bottom: 0.75px solid #707070;
  li {
    flex: 1;
    box-sizing: border-box;
    border-left: 0.75px solid #707070;
    padding: 1rem;
    background: #becccf;
    &.current {
      background: white;
    }
    button {
      font-size: var(--type-size-medium);
      font-weight: bold;
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      width: 100%;
      text-align: left;
    }
  }

  li:first-child {
    border-left: none;
  }
`;

function Browse(props) {
  const { setEmbeddedTermUri } = props;
  const views = ["Hierarchical", "Alphabetical"];
  const [currentView, setCurrentView] = useState(views[0]);
  const selectView = (view) => {
    switch (view) {
      case "Alphabetical":
        return <Alphabetical setEmbeddedTermUri={setEmbeddedTermUri} />;
      case "Hierarchical":
        return <Hierarchical setEmbeddedTermUri={setEmbeddedTermUri} />;
      default:
        return null;
    }
  };

  return (
    <SidebarContainer headerText="Browse" HeaderIcon={BsEye} height="35rem">
      <>
        <Tabs>
          {views.map((view) => (
            <li key={view} className={currentView === view ? "current" : null}>
              <button
                type="button"
                href="#"
                onClick={() => setCurrentView(view)}
              >
                {view}
              </button>
            </li>
          ))}
        </Tabs>
        {selectView(currentView)}
      </>
    </SidebarContainer>
  );
}

Browse.propTypes = {
  setEmbeddedTermUri: PropTypes.func,
};

Browse.defaultProps = {
  setEmbeddedTermUri: null,
};

export default Browse;
