import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import styled, { keyframes } from "styled-components";
import download from "downloadjs";
import Select from "./Select";
import formats from "./formats";
import months from "./months";
import ViewResults from "./ViewResults";

const RadioButtons = styled.div`
  padding-bottom: 1rem;
  > label {
    padding-left: 0.1rem;
    padding-right: 1rem;
  }

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  gap: 1rem;
  > div {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    font-size: var(--type-size-medium);
    color: white;
    border: none;
    background-color: var(--color-nae-blue);
    height: 2rem;
    border-radius: 5px;
    :disabled {
      background-color: #c4c4c4;
    }
  }

  button:first-child {
    background-color: var(--color-nam-green);
    :disabled {
      background-color: #c4c4c4;
    }
  }
`;

const SpinnerAnimation = keyframes`
0% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(359deg);
          transform: rotate(359deg);
}
`;

const DownloadSpinner = styled(FaSpinner)`
  margin-left: 0.25rem;
  -webkit-animation: ${SpinnerAnimation} 2s infinite linear;
  animation: ${SpinnerAnimation} 2s infinite linear;
`;

const Hr = styled.hr`
  margin: 2rem 0;
`;

const ViewResultsReturnButton = styled.button`
  font-size: var(--type-size-medium);
  background-color: var(--color-nae-blue);
  color: white;
  border: none;
  height: 2rem;
  border-radius: 5px;
`;

const modes = ["By Date", "By Top-Level Term", "Entire TRT"];

function generateYears(startYear) {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = startYear; year <= currentYear; year += 1) {
    years.push(year);
  }
  return years;
}
const years = generateYears(2019);

function DownloadArea() {
  gtag('event', 'pageview');
  const [downloadMode, setDownloadMode] = useState(modes[0]);

  const [monthFrom, setMonthFrom] = useState("");
  const [yearFrom, setYearFrom] = useState("");
  const [topTermUri, setTopTermUri] = useState("");
  const [terms, setTerms] = useState([]);

  const [downloadFormat, setDownloadFormat] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [isViewingResults, setIsViewingResults] = useState(false);

  function doDownload(downloadLink) {
    if (!isDownloading) {
      setIsDownloading(true);
      let filename;
      fetch(downloadLink)
        .then((response) => {
          if (response.status === 200) {
            filename = response.headers.get("content-disposition");
            [, filename] = filename.split("filename=");
            return response.blob();
          }
          return "";
        })
        .then((body) => {
          download(body, filename, "application/octet-stream");
          setIsDownloading(false);
        });
    }
  }

  function createDownloadButton() {
    let buttonValid = false;
    let viewDisabled = true;

    if (downloadFormat) {
      const params = { format: downloadFormat };
      if (downloadMode === "By Top-Level Term" && topTermUri) {
        params.uri = topTermUri;
        buttonValid = true;
        viewDisabled = false;
      } else if (
        downloadMode === "By Date" &&
        monthFrom !== "" &&
        yearFrom !== ""
      ) {
        params.date = new Date(yearFrom, monthFrom, 1).toISOString();
        buttonValid = true;
        viewDisabled = false;
      } else if (downloadMode === "Entire TRT") {
        buttonValid = true;
        viewDisabled = false;
      }

      if (buttonValid) {
        return (
          <ButtonSection>
            <button type="button" onClick={() => setIsViewingResults(true)}>
              Preview Results
            </button>
            <button
              type="button"
              onClick={() =>
                doDownload(`/download/file?${new URLSearchParams(params)}`)
              }
            >
              Download {isDownloading && <DownloadSpinner />}
            </button>
          </ButtonSection>
        );
      }
    }

    if (
      (downloadMode === "By Top-Level Term" && topTermUri) ||
      (downloadMode === "By Date" && monthFrom !== "" && yearFrom !== "") ||
      downloadMode === "Entire TRT"
    ) {
      viewDisabled = false;
    }

    return (
      <ButtonSection>
        <button
          type="button"
          disabled={viewDisabled}
          onClick={() => {
            if (!viewDisabled) setIsViewingResults(true);
          }}
        >
          Preview Results
        </button>
        <button type="button" disabled>
          Download
        </button>
      </ButtonSection>
    );
  }

  useEffect(() => {
    fetch(`/api/getTopConcepts`)
      .then((resp) => resp.json())
      .then((data) => {
        const termsObj = {};
        data.forEach((term) => {
          const { uri, prefLabel } = term;
          termsObj[uri] = prefLabel;
        });
        setTerms(termsObj);
      });
  }, []);

  function changeDownloadMode(e) {
    const mode = e.target.value;
    setDownloadMode(mode);
    [setTopTermUri, setYearFrom, setMonthFrom].forEach((setter) => setter(""));
  }

  if (isViewingResults) {
    return (
      <div>
        <h2>Viewing TRT Download</h2>
        <ViewResultsReturnButton
          type="button"
          onClick={() => setIsViewingResults(false)}
        >
          Return to Download
        </ViewResultsReturnButton>
        <Hr />
        <ViewResults
          downloadMode={downloadMode}
          monthFrom={monthFrom}
          yearFrom={yearFrom}
          topTermUri={topTermUri}
        />
      </div>
    );
  }

  return (
    <div>
      <h2>Download TRT</h2>
      <p>To export the TRT, select an option and format below.</p>
      <Hr />
      <RadioButtons>
        {modes.map((mode) => (
          <label key={mode}>
            <input
              name="mode"
              id={mode}
              type="radio"
              value={mode}
              onChange={changeDownloadMode}
              checked={downloadMode === mode}
            />
            {mode}
          </label>
        ))}
      </RadioButtons>
      {downloadMode === "By Date" && (
        <>
          <p>
            Select a starting month and year to retrieve a file of any new TRT
            terms added or modified since that date.
          </p>
          <SelectContainer>
            <Select
              placeholder="Month"
              // note that the date constructor takes 0 to mean January!
              options={months.map((month, index) => ({
                label: month,
                value: index,
              }))}
              value={monthFrom}
              onChange={(option) => setMonthFrom(option.value)}
            />
            <Select
              placeholder="Year"
              options={years.map((year) => ({ label: year, value: year }))}
              value={yearFrom}
              onChange={(option) => setYearFrom(option.value)}
            />
          </SelectContainer>
        </>
      )}
      {downloadMode === "By Top-Level Term" && (
        <>
          <p>Choose a top-level term:</p>
          <Select
            placeholder="Top-Level Term"
            options={Object.entries(terms)
              .sort((a, b) => (a[1] > b[1] ? 1 : -1))
              .map(([uri, prefLabel]) => ({
                label: prefLabel,
                value: uri,
              }))}
            value={topTermUri}
            onChange={(option) => setTopTermUri(option.value)}
          />
        </>
      )}
      <Hr />
      <SelectContainer>
        <Select
          placeholder="Download Format"
          options={Object.entries(formats).map(([key, label]) => ({
            label,
            value: key,
          }))}
          value={downloadFormat}
          onChange={(option) => setDownloadFormat(option.value)}
        />
      </SelectContainer>
      {createDownloadButton()}
      <Hr />
    </div>
  );
}

export default DownloadArea;
