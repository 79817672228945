import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled from "styled-components";

import paths from "./paths";
import Container from "./Container";
import Browse from "./browse/Browse";
import Search from "./Search";
import Contact from "./Contact";
import HotTopics from "./HotTopics";
import Header from "./Header";
import RecentlyAdded from "./RecentlyAdded";
import Footer from "./Footer";
import DownloadArea from "./download/DownloadArea";
import TermDisplay from "./TermDisplay";
import EmbeddableView from "./EmbeddableView";
import TermSuggestion from "./TermSuggestion";
import HowToUse from "./HowToUse";
import AboutTRT from "./AboutTRT";
import Resources from "./Resources";
import SearchResults from "./SearchResults";
import Home from "./Home";


const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-bottom: 2rem;
  @media (max-width: 576px) {
    width: auto;
  }
`;

const Main = styled(Container)`
  display: flex;
  column-gap: 4rem;
  padding-top: 3rem;

  @media (max-width: 768px) {
    padding-top: 1.5rem;
    column-gap: 3rem;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    width: auto;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
`;

// Only show Resources at the bottom in mobile view.
const ResourcesContainer = styled.div`
  @media (min-width: 577px) {
    display: none;
  }
`;


function App() {
  return (
      <Router>
      <Switch>
        <Route path={paths.embed}>
          <EmbeddableView />
        </Route>
        <Route path={paths.home}>
          <Header />
          <Main as="main">
            <Column>
                <Switch>
                    <Route path="/term/:termId">
                        <TermDisplay />
                    </Route>
                    <Route path="/search">
                        <SearchResults />
                    </Route>
                    <Route path={paths.contactTrb}>
                        <Contact />
                    </Route>
                    <Route path={paths.hotTopicTerms}>
                        <HotTopics />
                    </Route>
                    <Route path={paths.howToUse}>
                        <HowToUse />
                    </Route>
                    <Route path={paths.suggestTerms}>
                        <TermSuggestion />
                    </Route>
                    <Route path={paths.download}>
                        <DownloadArea />
                    </Route>
                    <Route path={paths.about}>
                        <AboutTRT />
                    </Route>
                    <Route path={paths.home}>
                        <Home />
                    </Route>
                </Switch>
            </Column>
            <Column>
              <Search />
              <Browse />
              <RecentlyAdded />
              <Switch>
                <Route exact path={paths.home}>
                  <ResourcesContainer>
                    <Resources />
                  </ResourcesContainer>
                </Route>
              </Switch>
            </Column>
          </Main>
          <Footer />
        </Route>
              </Switch>
    </Router>
    );
}

export default App;
