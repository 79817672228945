import React from "react";
import styled from "styled-components";
import { BsCameraVideo } from "react-icons/bs";

const Header = styled.h3`
  padding-bottom: 1rem;
`;

const LinksBox = styled.ul`
  background: var(--color-pale-background);
  padding: 1rem;
  margin: 0;
  line-height: 1.5;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  li {
    list-style: none;
  }

  a {
    color: var(--color-nae-blue);

    display: flex;
    gap: 1rem;
    align-items: center;
  }

  a > svg {
    flex: 0 0 1.5rem;
  }

  svg > path {
  fill: var(--color-ocean-blue);
}

`;

const links = [
  {
    text: "The TRT:  Your Multi-Tool for Finding and Organizing Transportation Information",
    url: "https://vimeo.com/266159036",
    isVideo: true,
    title: 'Video link'
  },
  {
    text: "Super Searching with the TRT",
    url: "https://vimeo.com/266158951",
    isVideo: true,
    title: 'Video link'
  },
  {
    text: "Keywords for the Technical Report Documentation Page: Let the TRT Help",
    url: "https://vimeo.com/496881977",
    isVideo: true,
    title: 'Video link'
  },
  {
    text: "About TRB’s Transportation Research Information Services (TRIS)",
    url: "http://www.trb.org/InformationServices/InformationServices.aspx",
  },
  { text: "TRID--the TRIS and ITRD Database", url: "https://trid.trb.org/" },
  {
    text: "Research in Progress (RIP) Database",
    url: "https://rip.trb.org/",
  },
  { text: "TRB Publications Index", url: "https://pubsindex.trb.org/" },
  {
    text: "TRIS Databases: Documentation & Training Materials",
    url: "http://www.trb.org/InformationServices/ResourcesfortheTRISDatabases.aspx",
  }
];

function Resources() {
  return (
    <>
      <Header>Resources</Header>
      <LinksBox>
        {links.map((link) => (
          <li key={link.url}>
            <a href={link.url} title={link.title}>
            {link.isVideo && (<BsCameraVideo size="1.5rem" />)}
              <span>{link.text}</span>
            </a>
          </li>
        ))}
      </LinksBox>
    </>
  );
}

export default Resources;
