import React, { useState, useEffect } from "react";
import { useTransition, animated } from "react-spring";
import styled from "styled-components";
import {
  BsPlusCircle as PlusIcon,
  BsDashCircle as MinusIcon,
} from "react-icons/bs";
import StatusMessage from "./StatusMessage";
import TermLink from "./TermLink";

const HotTopic = styled.div`
  :first-child {
    margin-top: 2em;
  }
  padding: 0.5rem 0;
  border-bottom: 1.5px solid #c4c4c4;
`;

const HotTopicHeader = styled.h3`
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: none;
    border: none;
    padding: 0;
    &:hover {
      cursor: pointer;
    }
  }

  div:first-child {
    display: flex;
    align-items: center;
    font-size: var(--type-size-larger);
    font-weight: 400;

    img {
      margin-right: 1em;
      width: 3.5em;
    }
  }

  a {
    font-size: var(--type-size-larger);
    font-weight: 400;
  }

  margin: 0;
  padding: 0rem;
`;

const pStyle = {
    margin: "10px 0px 0px"
};

const aStyle = {
    color: "var(--color-academies-blue)"
}

const iconStyles = { color: "var(--color-academies-blue)", size: "1.25rem" };

function HotTopics() {
  gtag('event', 'page_view');
  const [hotTopics, setHotTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedResultIndex, setExpandedResultIndex] = useState(-1);
  const [errorMessage, setErrorMessage] = useState("");

  const transitions = useTransition(
    expandedResultIndex !== -1 && expandedResultIndex,
    {
      from: { opacity: 0, maxHeight: "0px" },
      enter: { opacity: 1, maxHeight: "1000px" },
      leave: { opacity: 0, maxHeight: "0px" },
    }
  );

  useEffect(() => {
    fetch("/api/getHotTopics")
      .then((resp) => resp.json())
      .then((data) => {
        data.forEach((hotTopic) => {
          hotTopic.concepts.sort((a, b) =>
            a.prefLabel.toLowerCase() > b.prefLabel.toLowerCase() ? 1 : -1
          );
        });

        data.sort((a, b) =>
          a.prefLabel.toLowerCase() > b.prefLabel.toLowerCase() ? 1 : -1
        );
        setHotTopics(data);
        setLoading(false);
        setExpandedResultIndex(-1);
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage("Error: Unable to retrieve hot topics.");
      });
  }, []);

  if (loading) {
    return "Loading...";
  }

  return (
    <>
      <h2>Hot Topic Terms</h2>
      <p>Looking for TRT terms on a popular topic? We have some suggestions:</p>
      {errorMessage && <StatusMessage message={errorMessage} type="error" />}
      {hotTopics.length > 0 &&
        hotTopics.map((hotTopic, hotTopicIndex) => (
          <HotTopic key={`${hotTopic.uri}`}>
            <HotTopicHeader>
              <button
                type="button"
                onClick={
                  hotTopicIndex !== expandedResultIndex
                    ? () => setExpandedResultIndex(hotTopicIndex)
                    : () => setExpandedResultIndex(-1)
                }
              >
                <div>
                  <img
                    alt={hotTopic.imageAltText || ""}
                    src={hotTopic.imageUrl}
                  />
                  {hotTopic.prefLabel}
                </div>
                {hotTopicIndex === expandedResultIndex ? (
                  <MinusIcon color={iconStyles.color} size={iconStyles.size} />
                ) : (
                  <PlusIcon color={iconStyles.color} size={iconStyles.size} />
                )}
              </button>
            </HotTopicHeader>
            {hotTopicIndex === expandedResultIndex &&
              transitions(
                (styles, item) =>
                  item === expandedResultIndex && (
                    <animated.div style={styles}>
                      <ul>
                        {hotTopic.concepts.map((concept) => (
                          <li key={concept.uri}>
                            <TermLink
                              uri={concept.uri}
                              label={concept.prefLabel}
                            />
                          </li>
                        ))}
                      </ul>
                    </animated.div>
                  )
              )}
            </HotTopic>
        ))}
          <p style={pStyle}>Also check out our <a href="https://trid.trb.org/Topics" style={aStyle}>Hot Topics page on TRID</a>.
              Hot Topics are preconfigured TRID searches that capture the latest publications and projects added to the Database.
              The URL can be copied and pasted to an email, document, or website. The URL will run a TRID search when clicked.</p>
    </>
  );
}

export default HotTopics;
