import React from "react";
import styled from "styled-components";
import TermDetailImage from "./images/term-detail.png";
import FindTermImage from "./images/find-term.png";
import paths from "./paths";

const Figure = styled.figure`
  margin: 0;
  padding: 1rem 0.25rem;
  img {
    width: 100%;
    border: 1px solid lightgrey;
  }
  figcaption {
    font-size: var(--type-size-smaller);
    font-style: italic;
  }
`;

const Link = styled.a`
  color: var(--color-academies-blue);
  &:visited {
    color: var(--color-academies-blue);
  }
`;

function HowToUse() {
  gtag('event', 'page_view');
  return (
    <>
      <h2>How to Use the TRT</h2>
      <section>
        <p>
          The Transportation Research Thesaurus (TRT) contains approximately
          9,500 preferred terms and 2,500 non-preferred terms.
        </p>
        <p>
        Using the preferred term ensures the same word is used consistently to refer to the identical concept.
        </p>
        <p>
          Non-preferred terms are synonyms, variant names or alternative
          spellings of preferred terms. Non-preferred terms direct the user to
          the preferred term for the concept in question.
        </p>
        <p>
          TRT terms are organized hierarchically under 21 general top-level
          terms. Proper names, including geographic names, are NOT included in
          the TRT.
        </p>
      </section>
      <section>
        <h3>Find a Term</h3>
        <Figure>
          <img src={FindTermImage} alt="Search and Browse" />
        </Figure>
        <h4>Search</h4>
        <p>
          Type a word or string of letters in the search field. As you type,
          potential matches appear in a drop-down list. You may click on any
          term in this list to see the term display. Or, click the 🔍 button to
          see a list of all matches starting with or containing that string. The
          best match will appear first, followed by other matches in
          alphabetical order. For example, “access” will return Access, Access
          control (Communications), Access control (Transportation), Access
          roads, Accessibility, etc.
        </p>
        <p>
          Searches are performed on both preferred terms and non-preferred
          terms. Non-preferred terms appear in italics and will also display the
          preferred term.
        </p>
        <p>
          Boolean operators OR and AND can be used to broaden or narrow search
          results. Boolean operators must be typed in upper case.
        </p>
        <h4>Browse the Hierarchical Index</h4>
        <p>
          Clicking on hierarchy produces a list of all 21 top-level terms for
          the hierarchies. Click on the arrow beside a specific top-level term
          to see a list of narrower terms belonging to that hierarchy. Clicking
          on a term in the term list will display the term record.
        </p>
        <h4>Browse the Alphabetical Index</h4>
        <p>
          Clicking on a letter in the Browse Alphabetical list produces an
          alphabetical list of all terms beginning with that letter. Click on a
          term in the alphabetical list to display the term record. In the
          alphabetical indexes, non-preferred terms will include a link to the
          preferred term.
        </p>
      </section>
      <section>
        <h3>Display a Term</h3>
        <p>
          To see a term’s full record, click on the term in the results display
          when using either the Search or Browse features. The record shows the
          term’s semantic network: hierarchical position, broader term, narrower
          terms, non-preferred (i.e., Use For terms) and related terms.
        </p>
        <p>Some preferred terms also include definitions and scope notes.</p>
        <Figure>
          <img src={TermDetailImage} alt="Term Detail view" />
          <figcaption>Example of Term Record</figcaption>
        </Figure>
      </section>
      <section>
        <h3>Find Related Records</h3>
        <p>
          TRT terms are used to index records in TRB’s TRID, Research in
          Progress and Publications Index databases. To search for a particular
          term in one of these databases, click the appropriate link in the term
          record.
        </p>
      </section>
      <section>
        <h3>Download Options</h3>
        <p>
          Each term can be downloaded in N3, Binary RDF, RDF XML, CSV, or Excel format by choosing an option at the bottom of the term’s record.
        </p>
        <p>
          You also may export the entire TRT, or a customized portion by date or
          top-level term, on the <Link href={paths.download}>Download</Link>{" "}
          page.
        </p>
      </section>
    </>
  );
}

export default HowToUse;
