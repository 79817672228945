import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Button = styled.button`
  background-color: var(--color-nae-blue);
  font-size: var(--type-size-smaller);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
  flex-shrink: 0;

  &.was-added {
    background-color: var(--color-nam-green);
  }
`;

function AddToRecordButton(props) {
  const { termLabel, termUri } = props;

  const [wasAdded, setWasAdded] = useState(false);

  // the URL of the page rendering the tool. Will use wildcard if this enviroment variable isn't provided.
  const parentDomain = process.env.EMBED_PARENT_DOMAIN;

  return (
    <>
      <Button
        className={wasAdded ? "was-added" : null}
        type="button"
        onClick={() => {
          window.parent.postMessage(
            { termLabel, termUri },
            parentDomain || "*"
          );
          setWasAdded(true);
        }}
        disabled={wasAdded}
        added={wasAdded}
      >
        {wasAdded ? "Added to Record" : "Add to Record"}
      </Button>
    </>
  );
}

AddToRecordButton.propTypes = {
  termLabel: PropTypes.string.isRequired,
  termUri: PropTypes.string.isRequired,
};

export default AddToRecordButton;
