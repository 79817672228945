import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  BsExclamationCircle as ErrorIcon,
  BsCheckCircle as SuccessIcon,
} from "react-icons/bs";

const Container = styled.div`
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 10px;
  display: flex;
  align-items: center;

  &.error {
    background-color: var(--color-orange);
  }

  &.success {
    background-color: var(--color-nam-green);
  }

  &.error,
  &.success {
    color: white;
  }

  svg {
    flex-shrink: 0;
    padding-right: 0.5rem;
  }
`;

function StatusMessage(props) {
  const { message, type } = props;

  let Icon;
  let iconColor;

  switch (type) {
    case "error":
      Icon = ErrorIcon;
      iconColor = "white";
      break;
    case "success":
      Icon = SuccessIcon;
      iconColor = "white";
      break;
    default:
      break;
  }

  return (
    <Container className={type}>
      <Icon color={iconColor} size="1rem" />
      {message}
    </Container>
  );
}

StatusMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default StatusMessage;
